import { PaginationProperties, TableDataProperties } from "../../../shared/uiComponents/Table/tableProperties";
import {
    AssessmentOptionsProperties,
    SoapNoteAssessmentType,
} from "../../../pages/SOAPNote/SoapNoteProperties/assessmentTypeProperties";
import {
    BehaviorTreatmentModificationOptionsProperties,
    SoapNoteBehaviorTreatmentModificationType,
} from "../../../pages/SOAPNote/SoapNoteProperties/behaviorTreatmentModificationTypeProperties";
import {
    BehaviorTreatmentOptionsProperties,
    SoapNoteBehaviorTreatmentType
} from "../../../pages/SOAPNote/SoapNoteProperties/behaviorTreatmentTypeProperties";
import {
    DirectSupervisionOptionsProperties,
    SoapNoteDirectSupervisionType
} from "../../../pages/SOAPNote/SoapNoteProperties/directSupervisionTypeProperties";
import {
    ParentTrainingOptionsProperties,
    SoapNoteParentTrainingType
} from "../../../pages/SOAPNote/SoapNoteProperties/parentTrainingTypeProperties";
import {
    SoapNoteTreatmentPlanningType,
    TreatmentPlanningOptionsProperties
} from "../../../pages/SOAPNote/SoapNoteProperties/treatmentPlanningTypeProperties";
import { InfoClientProperties } from "./clientProperties";
import {
    SessionTypeProperties,
    UserBriefInfoProperties
} from "./calendarProperties";
import { ErrorProperties } from "../identityAPIProperties";

export interface GetSoapNotesProperties extends PaginationProperties {
    status?: string,
    startDate?: string;
    endDate?: string;
    clientId?: string;
    userId?: string
}

export interface GetSoapNoteProperties {
    clientId: string;
    reportId: string;
}

export interface StatusProperties {
    status: number,
    name: string
}

export interface LocationTypes {
    id: string;
    name: string;
    locationType: number;
    telehealth: boolean;
}

export interface TelehealthTypes {
    id: string;
    name: string;
    type: number;
}

export enum TelehealthLocationTypes {
    "Client" = 1,
    "User" = 2,
}

export interface InitialSoapNoteStateProperties {
    loading: boolean;
    behaviorTreatmentOptions: BehaviorTreatmentOptionsProperties;
    behaviorTreatmentModificationOptions: BehaviorTreatmentModificationOptionsProperties;
    directSupervisionOptions: DirectSupervisionOptionsProperties;
    assessmentOptions: AssessmentOptionsProperties;
    parentTrainingOptions: ParentTrainingOptionsProperties;
    treatmentPlanningOptions: TreatmentPlanningOptionsProperties;
    locations: Array<LocationTypes> | null;
    telehealtLocations: Array<TelehealthTypes>;
    soapNote:
    SoapNoteBehaviorTreatmentType |
    SoapNoteDirectSupervisionType |
    SoapNoteAssessmentType |
    SoapNoteBehaviorTreatmentModificationType |
    SoapNoteParentTrainingType |
    SoapNoteTreatmentPlanningType | any;
    soapNotes: SoapNotesDataProperties;
    error: ErrorProperties;
    soapNoteData: {
        supervisor: {
            id: string;
            fullName: string;
            role: string;
            authoritationType: {
                id: number,
                name: string;
            }
        } | null
    }
}

export interface SoapNotesFullProperties {
    id: string,
    reportType: string,
    sessionType: SessionTypeProperties,
    sessionId: string,
    date: string,
    startTime: string,
    endTime: string,
    createdBy: UserBriefInfoProperties,
    createdAt: string,
    status: StatusProperties,
    submittedBy: UserBriefInfoProperties | null,
    submittedAt: string | null,
    user: UserBriefInfoProperties,
    client: InfoClientProperties,
    location: LocationTypes,
}

export interface SoapNotesBehaviorTreatmentFullProperties extends SoapNotesFullProperties {
    supervisor: UserBriefInfoProperties,
    behaviorTechName: string,
}

export interface SoapNotesDataProperties extends TableDataProperties {
    query: Array<SoapNotesFullProperties> | null;
}

export interface OptionProperties {
    id: string | number;
    text: string;
}

export interface UpdateBillingCredentialedProperties {
    userId: string,
    startDate: string;
    clientIds: Array<string>;
}

export interface GetExcelReportProperties {
    startDate?: string | null;
    endDate?: string | null;
    userId?: string | null;
    clientId?: string | null;
}
